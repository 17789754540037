import { STATIC_CDN_IMAGES } from "@/config/constants";
import { logout } from "@/utils/auth";
import Image from "next/image";
import { Fragment, memo, useMemo } from "react";
import FaClose from "../SVG/Fontawesome/FaClose";
import FaUserSolid from "../SVG/Fontawesome/FaUserSolid";
import ThemeChanger from "./ThemeChanger";
import { courseNavigationData, examNavigationData, getNcertNavigationData } from "@/config/navigation";
import Link from "next/link";
import LinkWrapper from "../LinkWrapper";
import { useDispatch, useSelector } from "react-redux";
import { resetOffCanvasView, updateOffCanvasView } from "@/store/slices/offCanvas";
import AccordionItem from "../Accordion/AccordionItem";
import { isAndroid } from "react-device-detect";
import { resetUser, selectUser } from "@/store/slices/auth";

const menu = [
  {
    name: "Books",
    content: [
      { name: "Class 12", url: "/books/class-12-all-books-download-questions-answers-solutions" },
      { name: "Class 11", url: "/books/class-11-all-books-download-questions-answers-solutions" },
      { name: "Class 10", url: "/books/class-10-all-books-download-questions-answers-solutions" },
      { name: "Class 9", url: "/books/class-9-all-books-download-questions-answers-solutions" },
      { name: "Class 8", url: "/books/class-8-all-books-download-questions-answers-solutions" },
      { name: "Class 7", url: "/books/class-7-all-books-download-questions-answers-solutions" },
      { name: "Class 6", url: "/books/class-6-all-books-download-questions-answers-solutions" },
    ],
  },
  { name: "Online Class", url: "/course", next: true },
  { name: "Ask Doubt on Whatsapp", url: "https://api.whatsapp.com/send?phone=918400400400&text=Hi", xtraProps: { rel: "noindex, nofollow" } },
  { name: "Search Doubtnut", url: "/search", next: true },
  { name: "Blog", url: "/blogs" },
  { name: "Get App", url: "/online-study-app" }];

const HamburgerSection = (props: { level: number; sectionId: string; parentSectionId: string; name: string; content?: any[]; url?: string; next?: boolean | string; xtraProps: any }) => {
  return <>
    <ul className="relative px-1 m-2">
      {
        props.url && <HamburgerFixed level={props.level} sectionId={`sideNavSec-${props.sectionId}`} name={props.name} url={props.url} next={props.next} xtraProps={props.xtraProps} />
      }
      {
        props.content && <AccordionItem level={props.level} sectionId={`sideNavSec-${props.sectionId}`} parentSectionId={`${props.parentSectionId}-${props.sectionId}`} parentClassName={`${props.parentSectionId}`} name={props.name} content={props.content} />
      }
    </ul>
    {/* <hr className="my-2 mx-2 dark:bg-gray-700" /> */}
  </>;
}

export const HamburgerFixed = (props: { level: number; sectionId: string; name: string; url: string, next?: boolean | string, xtraProps: any }) => {
  const fontWeight = props.level === 1 ? "font-medium" : "font-normal";
  const dispatch = useDispatch();

  const closeHamburger = () => {
    dispatch(resetOffCanvasView());
  }

  if (props.next) {
    return <li className="relative" id={props.sectionId}>
      <LinkWrapper onClick={closeHamburger} className={`flex items-center text-base py-2 px-4 h-7 ${fontWeight} text-ellipsis whitespace-nowrap rounded hover:text-accent hover:bg-secondary-dark ease-in-out`} href={props.url} {...props.xtraProps}>
        <span>{props.name}</span>
      </LinkWrapper>
    </li>;
  }
  return <li className="relative" id={props.sectionId}>
    <a onClick={closeHamburger} className={`flex items-center text-base py-2 px-4  h-7 ${fontWeight} text-ellipsis whitespace-nowrap rounded hover:text-accent hover:bg-secondary-dark ease-in-out`} href={props.url} {...props.xtraProps}>
      <span>{props.name}</span>
    </a>
  </li>;
};

const HamburgerCollapsible = (props: { level: number; sectionId: string; parentSectionId: string; name: string; content: any[] }) => {
  const fontWeight = props.level === 1 ? "font-semibold" : "font-medium";

  return <li className="relative" id={props.sectionId}>
    <Link href="#" className={`flex items-center text-base py-4 my-1 sm:py-6 px-3 pl-${props.level * 2} h-7 ${fontWeight} text-ellipsis whitespace-nowrap rounded hover:text-primary hover:bg-secondary cursor-pointer [overflow-anchor:none] !visible`} data-te-target={`#collapseSidenavSec-${props.sectionId}`} aria-expanded="false" aria-controls={`collapseSidenavSec-${props.sectionId}`} data-te-collapse-init>
      <span>{props.name}</span>
      <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
        <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
      </svg>
    </Link>
    <ul className="relative !visible hidden pl-2" id={`collapseSidenavSec-${props.sectionId}`} aria-labelledby={props.sectionId} data-te-parent={props.parentSectionId} data-te-collapse-item>
      {
        props.content.map(((item, i) => {
          return <Fragment key={i.toString()}>
            {
              (item.url && !item.content) && <HamburgerFixed level={props.level + 1} sectionId={`${props.sectionId}-${i}`} name={item.name} url={item.url} next={item.next} xtraProps={item.xtraProps} />
            }
            {
              item.content && <HamburgerCollapsible level={props.level + 1} sectionId={`${props.sectionId}-${i}`} parentSectionId={`${props.parentSectionId}-${props.sectionId}`} name={item.name} content={item.content} />
            }
          </Fragment>
        }))
      }
    </ul>
  </li>;
}

const HamburgerProfile = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const authenticated = useMemo(() => {
    if(!user.id) return false;
    return true;
  }, [user]);

  const onLinkClick = () => {
    if (authenticated) {
      logout();
      dispatch(resetUser());
      return;
    }

    dispatch(resetOffCanvasView());
    if (!isAndroid) {
      dispatch(updateOffCanvasView({ name: "LOGIN", data: { LOGIN: { method: "OTP" } } }));
      return;
    }
    dispatch(updateOffCanvasView({ name: "LOGIN", data: { LOGIN: { method: "TRUECALLER" } } }));
  };

  return <div className="py-2 px-1 pl-4 space-y-1">
    <button name={authenticated ? "logout-btn-hamburger" : "login-button-hamburger"} id={authenticated ? "logout-btn-hamburger" : "login-button-hamburger"} onClick={onLinkClick}>
      <div className="flex items-center">
        <FaUserSolid className="text-accent" />
        <div className="grow ml-3">
          <p className="text-base font-semibold text-accent">{authenticated ? "Logout" : "Login"}</p>
        </div>
      </div>
    </button>
    {
      (authenticated && user.vipData?.course.enabled) && <ul className="pl-0">
        <HamburgerFixed level={1} sectionId="sideNavSec-course" name="My Course" url="/my-course" next xtraProps={{}} />
      </ul>
    }
    {
      authenticated && <ul className="pl-0">
        <HamburgerFixed level={1} sectionId="sideNavSec-profile" name="My Profile" url="/profile" next xtraProps={{}} />
      </ul>
    }
  </div>;
}

const Hamburger = () => {
  const menuItems = [];
  const dispatch = useDispatch();

  const handleCloseClick = () => {
    dispatch(resetOffCanvasView());
  }

  menuItems.push(...getNcertNavigationData());
  menuItems.push({ name: "Courses", content: courseNavigationData });
  menuItems.push(examNavigationData);
  menuItems.push(...menu);

  return (
    <div>
      <div className="flex items-center justify-between p-4">
        <Image
          src={`${STATIC_CDN_IMAGES}/mweb-us-icon-regular-logo.svg`}
          alt="mweb-us-icon-regular-logo"
          width={85}
          height={20}
        />
        <button onClick={handleCloseClick} id="hamburger-close" type="button" className="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" data-te-offcanvas-toggle data-te-target="#offcanvasRight" aria-label="Close">
          <FaClose className="text-xl text-body" />
        </button>
      </div>
      <div className="flex-grow px-2 overflow-y-auto">
        <div className="w-full h-full py-1 pb-12 bg-secondary-light overflow-y-auto rounded" id="sidenavSecHamburger">
          {/* <hr className="my-0 mx-4" /> */}
          <HamburgerProfile />
          <hr className="my-0 mx-4" />
          {
            menuItems.map((menuItem, i) => {
              return <HamburgerSection key={i.toString()} level={1} sectionId={i.toString()} parentSectionId={"sidenavSecHamburger"} name={menuItem.name} content={menuItem.content} url={menuItem.url} next={menuItem.next} xtraProps={menuItem.xtraProps} />
            })
          }
          <hr className="my-4" />
          <div className="px-4 mx-2 flex justify-center items-center gap-2">
            <span className="w-max font-medium grow">Select Theme</span>
            <ThemeChanger className="w-min border border-primary" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Hamburger);

